import { ChakraProvider, CSSReset, GlobalStyle } from '@chakra-ui/react';
import { ConfigProvider, notification } from 'antd';
import locale from 'antd/locale/th_TH';
import ChakraThemeAdmin from 'chakra-theme-admin';
import { axiosSwr } from 'libs/axiosSwr';
import { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, useEffect } from 'react';
import 'swiper/css';
import 'swiper/scss/pagination';
import { SWRConfig } from 'swr';
import ChakraTheme from '../chakra-theme';
import '../styles/globals.scss';

import dayjs from 'dayjs';
import th from 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';

import NextNProgress from 'nextjs-progressbar';
import ErrorBoundary from 'components/errors/ErrorBoundary';

dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(buddhistEra);
dayjs.extend(isSameOrAfter);
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);
dayjs.locale('th');
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Bangkok');

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();

  const getLayout = Component.getLayout ?? ((page) => page);

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const handleRouteChange = () => {
      window.scrollTo(0, 0);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  // set dayjs locale
  useEffect(() => {
    dayjs.locale(router.locale === 'th' ? th : undefined);
  }, [router.locale]);

  return (
    <ErrorBoundary>
      <ChakraProvider
        theme={
          router.pathname.startsWith('/admin') ? ChakraThemeAdmin : ChakraTheme
        }
      >
        <CSSReset />
        <GlobalStyle />
        <NextNProgress
          color="#000"
          height={3}
          showOnShallow={true}
          startPosition={0.3}
        />
        <ConfigProvider
          locale={router.locale === 'th' ? locale : undefined}
          theme={{
            token: {
              colorPrimary: '#f26522',
              colorInfo: '#f26522',
              fontFamily: '"Noto Sans Thai", sans-serif; !important',
            },
          }}
        >
          {contextHolder}
          <SWRConfig
            value={{
              fetcher: axiosSwr,
              refreshInterval: 0,
              onError: (error) => {
                let message = error.message;

                if (error.response) {
                  if (error.response.data) {
                    message = error.response.data.message;
                  }
                }

                api.error({
                  message: 'พบข้อผิดพลาด',
                  description: message,
                });
              },
              onErrorRetry: (
                error,
                key,
                config,
                revalidate,
                { retryCount }
              ) => {
                // log error
                console.log('error', error.response);

                // log error.response?.status
                console.log('error.response?.status', error.response?.status);

                // Never retry on 404.
                if (error.response?.status === 404) return;

                // Only retry up to 3 times.
                if (retryCount >= 3) return;
              },
            }}
          >
            {getLayout(<Component {...pageProps} />)}
          </SWRConfig>
        </ConfigProvider>
      </ChakraProvider>
    </ErrorBoundary>
  );
}

export default appWithTranslation(MyApp);
