import {
  extendTheme,
  withDefaultColorScheme,
  ThemeConfig,
  withDefaultSize,
} from '@chakra-ui/react';
import defaultTheme from '@chakra-ui/theme';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const ChakraTheme = extendTheme(
  {
    ...defaultTheme,
    config,
    fonts: {
      ...defaultTheme.fonts,
      heading: '"Kanit", sans-serif;',
      body: '"Kanit", sans-serif;',
    },
    colors: {
      ...defaultTheme.colors,
      primary: {
        '50': '#fef0e9',
        '100': '#fbd1bd ',
        '200': '#f9b291',
        '300': '#f69364',
        '400': '#f37438',
        '500': '#f26522',
        '600': '#da5b1f',
        '700': '#c2511b',
        '800': '#c2511b',
        '900': '#a94718',
      },
      secondary: {
        50: '#fdfcfa',
        100: '#f9f5f0',
        200: '#f5efe7',
        300: '#f0e8dd',
        400: '#ece1d3',
        500: '#eadece',
        600: '#d3c8b9',
        700: '#bbb2a5',
        800: '#a49b90',
        900: '#8c857c',
      },
      gray: {
        50: '#f0f0f0',
        100: '#E7E7E7',
        200: '#bfbdbc',
        300: '#94928f',
        400: '#7f7c78',
        500: '#696662',
        600: '#54504b',
        700: '#3e3a35',
        800: '#29241e',
        900: '#25201b',
      },
      green: {
        50: '#ebf7ed',
        100: '#d7f0db',
        200: '#afe0b6',
        300: '#88d192',
        400: '#60c16d',
        500: '#38B249',
        600: '#32a042',
        700: '#2d8e3a',
        800: '#277d33',
        900: '#226b2c',
      },
    },
    components: {
      Container: {
        baseStyle: {
          maxW: '850px',
        },
      },
      Alert: {
        baseStyle: {
          borderRadius: '0',
          container: {
            borderRadius: '0',
          },
          title: {
            fontWeight: '400',
          },
        },
      },
      Button: {
        baseStyle: (props: any) => ({
          boxShadow: 'none !important',
          borderRadius: '0',
          fontWeight: '400',
        }),
        sizes: {
          md: {
            h: '36px',
          },
        },
      },
      Heading: {
        baseStyle: {
          fontWeight: '300',
          color: 'gray.800',
        },
        defaultProps: {
          fontSize: 'md',
          colorScheme: 'gray.800',
        },
      },
      FormLabel: {
        baseStyle: {
          fontWeight: '300',
          color: 'gray.700',
        },
      },
      Input: {
        field: {
          borderRadius: '0',
        },
        sizes: {
          md: {
            field: {
              h: '36px',
              borderRadius: '0',
            },
          },
        },
      },
      Select: {
        field: {
          borderRadius: '0',
        },
        sizes: {
          md: {
            field: {
              h: '36px',
              borderRadius: '0',
            },
          },
        },
      },
      Modal: {
        baseStyle: {
          header: {
            fontWeight: '600',
            background: 'primary.500',
            color: 'white',
            borderTopRadius: 'md',
          },
          overlay: {
            background: 'rgba(0,0,0,0.75)',
          },
          dialog: {
            marginX: 4,
          },
        },
      },
      Table: {
        baseStyle: {
          th: {
            fontWeight: '600',
          },
          td: {},
        },
      },
      Toast: {
        baseStyle: {
          borderRadius: '0',
        },
      },
    },
    styles: {
      ...defaultTheme.styles,
      global: (props: any) => ({
        ...defaultTheme.styles.global,
        'html, body': {
          height: '100%',
          fontWeight: '300',
          fontFamily: 'body',
          color: 'gray.700',
        },
        'div#__next': {
          height: '100%',
        },
        strong: {
          fontWeight: '500',
        },
        '@media screen and (min-width: 992px)': {
          'html, body': {
            fontSize: 'md',
          },
        },
        '.chakra-alert': {
          borderRadius: '0 !important',
        },
      }),
    },
  },
  withDefaultColorScheme({
    colorScheme: 'primary',
  }),
  withDefaultSize({
    size: 'md',
  })
);

export default ChakraTheme;
