import {
  extendTheme,
  ThemeConfig,
  withDefaultColorScheme,
  withDefaultSize,
} from '@chakra-ui/react';
import defaultTheme from '@chakra-ui/theme';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const ChakraThemeAdmin = extendTheme(
  {
    ...defaultTheme,
    config,
    fonts: {
      ...defaultTheme.fonts,
      heading: '"Noto Sans Thai", sans-serif;',
      body: '"Noto Sans Thai", sans-serif;',
    },
    colors: {
      ...defaultTheme.colors,
      primary: {
        '50': '#fef0e9',
        '100': '#fbd1bd ',
        '200': '#f9b291',
        '300': '#f69364',
        '400': '#f37438',
        '500': '#f26522',
        '600': '#da5b1f',
        '700': '#c2511b',
        '800': '#c2511b',
        '900': '#a94718',
      },
      gray: {
        50: '#f0f0f0',
        100: '#e0e0e0',
        200: '#b3b3b3',
        300: '#949494',
        400: '#757575',
        500: '#5c5c5c',
        600: '#525252',
        700: '#3d3d3d',
        800: '#292929',
        900: '#1f1f1f',
      },
      green: {
        50: '#ebf7ed',
        100: '#d7f0db',
        200: '#afe0b6',
        300: '#88d192',
        400: '#60c16d',
        500: '#38B249',
        600: '#32a042',
        700: '#2d8e3a',
        800: '#277d33',
        900: '#226b2c',
      },
      orange: {
        50: '#fef7ea',
        100: '#fdeed5',
        200: '#fbddac',
        300: '#facd82',
        400: '#f8bc59',
        500: '#F6AB2F',
        600: '#e1a42e',
        700: '#c89229',
      },
    },
    components: {
      Container: {
        baseStyle: {
          maxW: '1140px',
        },
      },
      Button: {
        baseStyle: {
          boxShadow: 'none !important',
        },
      },
      FormLabel: {
        baseStyle: {
          fontWeight: '600',
        },
      },
      Modal: {
        baseStyle: {
          header: {
            fontWeight: '600',
            background: 'primary.500',
            color: 'white',
            borderTopRadius: 'md',
          },
          overlay: {
            background: 'rgba(0,0,0,0.75)',
          },
          dialog: {
            marginX: 4,
          },
        },
      },
      Table: {
        baseStyle: {
          th: {
            fontWeight: '600',
          },
          td: {},
        },
      },
    },
    styles: {
      ...defaultTheme.styles,
      global: (props: any) => ({
        ...defaultTheme.styles.global,
        'html, body': {
          height: '100%',
          fontWeight: '400',
          fontFamily: '"Noto Sans Thai", sans-serif; !important',
          fontSize: '14px !important',
          backgroundColor: '#f0f0f0',
        },
        'div#__next': {
          height: '100%',
        },
        a: {
          color: 'primary.500',
          _hover: {
            textDecoration: 'underline',
          },
          _focus: {
            boxShadow: 'none !important',
          },
        },
        '.media-modal': {
          '.ant-modal-content': {
            padding: '0 !important',
          },
        },
        '@media screen and (min-width: 992px)': {
          'html, body': {
            fontSize: 'md',
          },
        },
      }),
    },
  },
  withDefaultColorScheme({
    colorScheme: 'primary',
  }),
  withDefaultSize({
    size: 'md',
  })
);

export default ChakraThemeAdmin;
